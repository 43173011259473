<template>
  <ScheduleMangerActionStyled>
    <div>
      <ButtonSolid v-if="showBack" :disabled="isBackDisabled" @click="$emit('onBack')"> Back </ButtonSolid>
    </div>
    <div class="button-set-2">
      <ButtonSolid v-if="showNext" :disabled="isNextDisabled" @click="$emit('onNext')"> Next </ButtonSolid>
      <ButtonSolid v-if="showSubmit" :disabled="isSubmitDisabled" @click="$emit('onSubmit')"> Save </ButtonSolid>
    </div>
  </ScheduleMangerActionStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { ButtonSolid } from '@styles/buttons'

const ScheduleMangerActionStyled = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 0.75px solid ${props => chroma(props.theme.colors.navFontNormal).alpha(0.2).css()};
  .button-set-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
export default {
  components: {
    ScheduleMangerActionStyled,
    ButtonSolid,
  },
  props: {
    mode: {
      type: String,
      default: 'ADD',
    },
    isBackDisabled: {
      type: Boolean,
      default: true,
    },
    isNextDisabled: {
      type: Boolean,
      default: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    showNext: {
      type: Boolean,
      default: true,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
